@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Macondo+Swash+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');

html,
body,
#root,
.container,
.content {
  font-size: 16px;
  font-family: 'Nanum Gothic', sans-serif;
  height: 100%;
  width: 100%;
}

.container {
  .link {
    text-decoration: none;
  }
}

/* body {
  background-color: #e7f6f2;
  overflow-x: hidden;
  overflow-y: overlay;
} */

/* .container {
  display: flex;
  position: relative;
} */

::-webkit-scrollbar {
  width: 10px;
}

/** Track */
/* ::-webkit-scrollbar-track {
  background: #e0e0e0;
} */

/** Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/** Handle on Hover*/
/* ::-webkit-scrollbar-track:hover {
  background: #555;
} */
